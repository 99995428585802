<template>
  <div class="eventscontent">
    <div class="cardImg card" v-for="(event, index) in events" :key="index">
      <img :src="event.poster" class="myImg" />
      <div class="absolute">
        <h2>{{ event.title }}</h2>
        <span>{{ event.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      events: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await axios.get("/api");
        this.events = response.data.events; // دریافت آرایه کامل از API
      } catch (error) {
        console.error("Error loading data:", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/content.css"></style>
